import React from 'react'
import Layout from 'components/Layout/Layout';
import Income from 'components/Income/Income';
import SEO from 'components/seo';

const IncomeVerificationPage = () => (
  <Layout>
    <SEO title="Home" />
    <Income />
  </Layout>
)

export default IncomeVerificationPage
